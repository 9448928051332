import apply from './apply'
import invert from './invert'

/**
 * Export.
 *
 * @type {Object}
 */

export default {
  apply,
  invert,
}
